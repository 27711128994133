import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { BaseService } from "./base.service";
import { from, Observable, of } from "rxjs";
import { AuthCacheService, SignedUrlEntry, SignedUrlType } from "./auth-cache.service";
import { Constants, CookieKey, SignedUrlPolicyConstants } from "../shared/helper/constants";

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  public static isBeingGottenToken: boolean = false;

  private static lastRes: any = {
    clientId: null,
  };

  constructor(private baseService: BaseService) {
  }

  checkValidToken(): Observable<any>{
    return this.baseService.doPost('v2/CheckValidToken', null);
  }

  getNewToken(): Observable<string> {
    let refresh_token = localStorage.getItem("refresh_token");
    let hrefUrl = location.href;
    if(!refresh_token || refresh_token.trim() == '' ){
      this.gotoURLPage(hrefUrl);
    }

    let data: any = {
      client_id:
        AuthorizationService.lastRes.client_id ||
        this.getUrlParamByKey("client_id") ||
        "PortalWeb",
    };

    data.grant_type = "refresh_token";
    data.refresh_token = refresh_token;

    AuthorizationService.isBeingGottenToken = true;
    let that = this;
    let promise = new Promise<string>((resolve) => {
      let xhr = this.createRequest(
        this.authorityServer + "/connect/token",
        "POST",
        true
      );
      if (!xhr) return;
      xhr.onreadystatechange = function () {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          let res = JSON.parse(this.response);

          if (!res.access_token.startsWith(res.token_type)) {
            if(res.access_token == ''){
              that.gotoURLPage(hrefUrl);
            }
            res.access_token = res.token_type + " " + res.access_token;
          }
          res.client_id = data.client_id;

          AuthorizationService.lastRes = res;
          AuthCacheService.save({
            token: res.access_token,
            expireInSeconds: res.expires_in,
          });

          localStorage.setItem("token", res.access_token);
          localStorage.setItem("refresh_token", res.refresh_token);

          AuthorizationService.isBeingGottenToken = false;

          if (that.checkIfSignedUrlsExpired()) {
            that.registerSignedUrl().subscribe();
          }
          resolve(res.access_token);
        } else if (this.status != 200) {
          localStorage.clear();
          that.gotoURLPage(hrefUrl);
        }
      };

      xhr.send(
        Object.keys(data)
          .map(function (key) {
            return key + "=" + data[key];
          })
          .join("&")
      );
    });

    return from(promise);
  }

  createRequest(url: string, method: string, includeToken: boolean = false) {
    let token = (localStorage.getItem("token")
      ? localStorage.getItem("token")
      : AuthCacheService.get().token) ?? '';
    if (includeToken && (!token || token == "")){
      return null;
    }
    let xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    if (includeToken) {
      xhr.setRequestHeader("Authorization", token);
    }
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("Cache-control", "no-cache");
    xhr.setRequestHeader("Pragma", "no-cache");
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader(
      "Access-Control-Allow-Methods",
      "GET, POST, OPTIONS, PUT, PATCH, DELETE"
    );
    xhr.setRequestHeader(
      "Access-Control-Allow-Headers",
      "Access-Control-Allow-Headers, Origin,Accept, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
    );
    xhr.setRequestHeader("Access-Control-Allow-Credentials", "true");
    xhr.setRequestHeader("Source", "Web-portal");
    return xhr;
  }

  getToken(): Observable<string> {
    let code = this.getUrlParamByKey("code");
    let tokenFromVideoServer = this.getUrlParamByKey("t");
    if(tokenFromVideoServer){
      localStorage.setItem("token", tokenFromVideoServer);

    }
    if (code) {
      localStorage.setItem("code", code);
      localStorage.setItem(
        "redirect_url",
        encodeURIComponent(this.getRedirectUrl())
      );
    }
    let data: any = {
      client_id:
        AuthorizationService.lastRes.client_id ||
        this.getUrlParamByKey("client_id") ||
        "PortalWeb",
    };

    if (AuthorizationService.lastRes.refresh_token) {
      localStorage.setItem(
        "refresh_token",
        AuthorizationService.lastRes.refresh_token
      );
      data.grant_type = "refresh_token";
      data.refresh_token = AuthorizationService.lastRes.refresh_token;
    } else if (code) {
      data.grant_type = "authorization_code";
      data.code = code;
      data.redirect_uri = encodeURIComponent(this.getRedirectUrl());
    } else {
      return of('');
    }

    AuthorizationService.isBeingGottenToken = true;
    let that = this;
    let promise = new Promise<string>((resolve) => {
      let xhr = this.createRequest(
        this.authorityServer + "/connect/token",
        "POST",
        data.grant_type === "refresh_token"
      );
      if (!xhr) return;
      xhr.onreadystatechange = function () {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          let res = JSON.parse(this.response);

          if (!res.access_token.startsWith(res.token_type)) {
            res.access_token = res.token_type + " " + res.access_token;
          }
          res.client_id = data.client_id;

          AuthorizationService.lastRes = res;
          AuthCacheService.save({
            token: res.access_token,
            expireInSeconds: res.expires_in,
          });
          localStorage.setItem(
            "refresh_token",
            res.refresh_token);

          localStorage.setItem("token", res.access_token);

          AuthorizationService.isBeingGottenToken = false;


          if (that.checkIfSignedUrlsExpired()) {
            that.registerSignedUrl().subscribe();
          }

          resolve(res.access_token);
        }
      };
      xhr.send(
        Object.keys(data)
          .map(function (key) {
            return key + "=" + data[key];
          })
          .join("&")
      );
    });

    return from(promise);
  }

  registerSignedUrl(storeToLocal: boolean = false): Observable<boolean> {
    let promise = new Promise<boolean>((resolve, reject) => {
      let xhr = this.createRequest(
        `${Constants.DomainURL_V2}/cloudfront/signed_url`,
        "GET",
        true
      );
      if (xhr == null){
        resolve(true);
        return;
      }
      xhr.onreadystatechange = function () {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          let res: SignedUrlEntry[] = JSON.parse(this.response).map((r: any) => {
            return new SignedUrlEntry().fromJson(r);
          });
          if (storeToLocal) {
            localStorage.setItem(CookieKey.signedUrlKey, JSON.stringify(res));
          }
          AuthCacheService.saveSignedUrls(res);
          resolve(true);
        }
      };
      xhr.send();
    });
    return from(promise);
  }

  checkIfSignedUrlsExpired() {
    var recordingUrl = AuthCacheService.getSignedUrl(SignedUrlType.recordings);
    var uploadUrl = AuthCacheService.getSignedUrl(SignedUrlType.uploads);
    var systemUrl = AuthCacheService.getSignedUrl(SignedUrlType.system);
    return (this.isSignedUrlExpired(recordingUrl) || this.isSignedUrlExpired(uploadUrl) || this.isSignedUrlExpired(systemUrl));
  }

  isSignedUrlExpired(signedUrl: any): boolean {
    try {
      if (!signedUrl) return true;
     
      let policyString = signedUrl.value;
      if (!policyString && !policyString.includes(SignedUrlPolicyConstants.SignedUrlPolicyMatchString)) return true;
      policyString = policyString.substring(policyString.indexOf(SignedUrlPolicyConstants.SignedUrlPolicyMatchString) + 7, policyString.indexOf('&')).replaceAll('_', '');
      let endTime = this.parseJwtAndGetEndTime(policyString);
      let diffInHours = this.getDiffInHoursFromNow(endTime);
      if (diffInHours < 9) return true;
      return false;
    } catch{
      return true;
    }
  }

  parseJwtAndGetEndTime(base64Url: string): number {
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    let endTime = JSON.parse(jsonPayload).Statement[0].Condition.DateLessThan[SignedUrlPolicyConstants.SignedUrlEndTimeKey];
    if (typeof endTime === SignedUrlPolicyConstants.NumType) {
      return JSON.parse(jsonPayload).Statement[0].Condition.DateLessThan[SignedUrlPolicyConstants.SignedUrlEndTimeKey];
    } else {
      return 0;
    }
  }

  gotoLoginPage() {
    localStorage.clear();


    let returnUrl =
      `/connect/authorize/callback?client_id=PortalWeb` +
      `&redirect_uri=${encodeURIComponent(
        location.origin
      )}&response_type=code&scope=openid Rebus offline_access&response_mode=query`;

    if (
      location.search &&
      location.search.toLowerCase().indexOf("forwardurl") !== -1
    ) {
      let tmp = decodeURIComponent(this.getUrlParamByKey("forwardurl")).replace(
        /^.*\/\/[^\/]+/,
        ""
      );
      let domain = decodeURIComponent(this.getUrlParamByKey("forwardurl"))
        .replace(/^.*\/\//, "")
        .split(".");
      returnUrl += `&fu=${encodeURIComponent(tmp)}&subDomain=${
        domain.length > 1 ? domain[0] : ""
      }`;
    }

    let url = `${this.authorityServer}/?returnUrl=${encodeURIComponent(
      returnUrl
    )}`;

    let error = this.getUrlParamByKey("e");

    if (error) {
      url += `&error=${encodeURIComponent(error)}`;
    }

    // let keyactive = this.getUrlParamByKey("keyactive");

    // if (keyactive && location.pathname === "/login") {
    //   this.confirmEmail(keyactive, () => {
    //     location.href = url;
    //   });
    // } else {
      location.href = url;
    // }
  }
  
  get authorityServer() {
    let url = `https://login.${Constants.IdentityServerDomain}`;

    if (environment.ENV === "DEV") {
      url = "http://localhost:2144";
    }

    return url;
  }

  gotoURLPage(forwardUrl: string) {
    let url = this.buildURLGoto(forwardUrl);

    let keyactive = this.getUrlParamByKey("keyactive");

    // if (keyactive && location.pathname === "/login") {
    //   this.confirmEmail(keyactive, () => {
    //     location.href = url;
    //   });
    // } else {
      location.href = url;
    // }
  }

  private buildURLGoto(forwardUrl: string): string {
    let returnUrl =
      `/connect/authorize/callback?client_id=PortalWeb` +
      `&redirect_uri=${encodeURIComponent(
        location.origin
      )}&response_type=code&scope=openid Rebus offline_access&response_mode=query`;
    let tmp = decodeURIComponent(forwardUrl).replace(/^.*\/\/[^\/]+/, "");

    // let userProfile = JSON.parse(localStorage.getItem(CookieKey.currentUserProfilekey));
    let domain = window.location.hostname;

    //OPUS-3560
    //In case of root user, impersonating a tenant user, then we need to include tennant name in the domain.
    // if(userProfile != null && userProfile.impersonator != null) {
    //   let tenancyName = userProfile._tenant != null ? userProfile._tenant.name : userProfile.defaultLoginTenancy;
    //   domain = `${tenancyName}.${domain}`
    // }

    returnUrl += `&fu=${encodeURIComponent(tmp)}&domain=${domain}`;

    let url = `${this.authorityServer}/?returnUrl=${encodeURIComponent(
      returnUrl
    )}`;

    let error = this.getUrlParamByKey("e");

    if (error) {
      url += `&error=${encodeURIComponent(error)}`;
    }
    return url;
  }

  getURLNewTab(forwardUrl: string) {
    return this.buildURLGoto(forwardUrl);
  }

  gotoImpersonate(
    key: string,
    userId: number,
    tenantId: number,
    clientId: string = "PortalWeb"
  ) {
    let returnUrl =
      `/connect/authorize/callback?client_id=${clientId}` +
      `&redirect_uri=${encodeURIComponent(
        location.origin
      )}&response_type=code&scope=openid Rebus offline_access&response_mode=query`;
    let url = `${
      this.authorityServer
    }/account/LoginWithImpersonate?key=${key}&returnUrl=${encodeURIComponent(
      returnUrl
    )}`;
    if (userId > 0) {
      url = url + `&userImpersonateId=${userId}`;
    }
    if (tenantId > 0) {
      url = url + `&tenantImpersonateId=${tenantId}`;
    }

    location.href = url;
  }

  gotoInvitationCompletePage() {
    let returnUrl =
      `/connect/authorize/callback?client_id=PortalWeb` +
      `&redirect_uri=${encodeURIComponent(
        location.origin
      )}&response_type=code&scope=openid Rebus offline_access&response_mode=query`;

    let url = `${
      this.authorityServer
    }/account/invite-complete?returnUrl=${encodeURIComponent(returnUrl)}`;
    location.href = url;
  }

  getUrlParamByKey(key: string): string {
    let results = new RegExp("[?&]" + key + "=([^&#]*)", "i").exec(
      window.location.href
    );

    if (results == null) {
      return '';
    } else {
      return decodeURIComponent(results[1]) || "0";
    }
  }

  getRedirectUrl() {
    let codePos = location.search.indexOf("&code=");
    let url =
      location.origin + location.pathname + location.search.substr(0, codePos);
    let results = new RegExp("[?&]fu=([^&#]*)", "i").exec(url);

    if (results && results[1]) {
      let fu = encodeURIComponent(decodeURIComponent(results[1]));
      return url.replace(results[1], fu);
    } else {
      return url;
    }
  }
  
  getDiffInHoursFromNow(timestamp: number): number {
    let currentDate = new Date();
    let endDate = new Date(0);
    endDate.setUTCSeconds(timestamp);
    let diff = (endDate.getTime() - currentDate.getTime()) / 1000;
    diff /= (60 * 60);
    return Math.round(diff);
  }
  
}
